// Cliente bloco item
.cliente-item {
  display: block;
  margin: 5px 10px;
  border: 1px solid rgba(#000, .1);
  border-radius: 8px;
  overflow: hidden;

  // Imagem
  img {
    transition: .3s ease-out;
  }

  &:hover img {
    position: relative;
    z-index: 2;
    transform: scale(1.1);
  }
}
