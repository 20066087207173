// Foto
.servico-detalhe-foto {
  margin-bottom: 10px;

  // MOBILE
  @include mq(s) {
    margin-bottom: 20px;
  }
}

// Texto
.servico-detalhe-texto {
  // BOTÃO
  .btn-container {
    float: none;

    // MOBILE
    @include mq(s) {
      text-align: center;
    }
  }

  .info-curso {
    margin-bottom: 10px;

    i {
      color: $cor_primaria;
      margin-right: 10px;
    }

    b {
      color: $cor_fonte_escura;
    }

    span {
      color: $cor_primaria;
      font-size: 17px;
      font-weight: 600;
    }
  }
}

.segura-infos-adcs {
  float: left;
  width: 100%;
  margin-top: 20px;
}

.segura-infos-adicionais {
  background-color: $cor_fundo2;
  padding: 20px;
  margin-bottom: 20px;
  border-radius: 10px;

  .subtitulo {
    font-size: 17px;
  }
}
