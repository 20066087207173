// Seção
.home-blog {
  background: #fff;
}

// Bloco post destaque
.bloco-post-dest {
  position: relative;

  // MOBILE
  @include mq(s) {
    height: auto;
    margin-bottom: 20px;
  }

  // TABLET
  @include mq(m) {
  }

  // Foto
  figure {
    overflow: hidden;
    //box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);

    img {
      transition: 0.3s ease-out;
    }
  }
  &:hover figure img {
    transform: scale(1.1);
  }

  // Descrição
  > div {
    position: relative;
    z-index: 2;
    background: #f1f3f4;
    width: 100%;
    margin: 0 auto;
    margin-top: 0px;
    padding: 20px;
  }

  // Título
  h2 {
    font-size: 16px;
    line-height: 22px;
    color: $cor_fonte_escura;
    font-weight: bold;
    margin-bottom: 10px;
    transition: 0.3s ease-out;

    // TABLET
    @include mq(m) {
      font-size: 14px;
    }
  }
  &:hover h2 {
    color: $cor_primaria;
  }

  // Texto
  h3 {
    font-size: 14px;
    line-height: 20px;
    color: $cor_fonte_clara;
    margin-bottom: 10px;
    transition: 0.3s ease-out;

    // TABLET
    @include mq(m) {
      font-size: 14px;
    }
  }
  &:hover h3 {
    color: $cor_fonte_escura;
  }

  // Data
  span {
    color: $cor_fonte_clara;
    font-size: 14px;
    text-align: center;

    &.dia {
      float: left;
      width: 34px;
      text-align: center;
      font-size: 16px;
      background: #fff;
      padding: 1px 4px;
      border-radius: 5px;
      border-bottom-right-radius: 0px;
      border-bottom-left-radius: 0px;

      // TABLET
      @include mq(m) {
        margin-bottom: 5px;
      }

      // MOBILE
      @include mq(s) {
        font-size: 12px;
      }
    }

    &.mes {
      float: left;
      width: 34px;
      text-align: center;
      text-transform: uppercase;
      background: $cor_primaria;
      border-radius: 3px;
      color: #fff;
      font-size: 12px;
      padding: 1px 4px;

      // TABLET
      @include mq(m) {
        margin-bottom: 5px;
      }

      // MOBILE
      @include mq(s) {
        font-size: 12px;
      }
    }

    // TABLET
    @include mq(m) {
      font-size: 12px;
    }
  }
}
