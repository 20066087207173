// Formulários (Login, Cria Conta, Cadastrar Senha, etc)
.form-usuarios {
  // DESKTOP
  @include mq(l) {
    float: none;
    margin-left: auto;
    margin-right: auto;
  }

  // TABLET
  @include mq(m) {
    float: none;
    margin-left: auto;
    margin-right: auto;
  }
}

// Link recuperação
.link-rec {
  float: left;
  display: block;
  width: 100%;
  margin-top: -10px;
  font-size: 15px;
}

// Texto opção (Não possui conta? /Já possui conta?)
.texto.opt {
  float: left;
  width: 100%;
  color: $cor_fonte_escura;
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  margin-top: 20px;
}

// Espaçamento
.separator {
  float: left;
  width: 100%;
  height: 1px;
  margin: 10px 0;
}

// Título seção
.form-titulo {
  float: left;
  width: 100%;
  font-family: $fonte_geral;
  font-size: 20px;
  font-weight: bold;
  color: $cor_fonte_escura;
  margin-bottom: 20px;

  &.center {
    text-align: center;
  }

  &.blue {
    color: #007da5;
  }

  small {
    font-size: 13px;
    color: $cor_fonte_escura;
  }
}

// Campo CNPJ
.campo-cnpj {
  display: none;
}

// Texto opção login (Facebook)
.opt-login {
  float: left;
  width: 100%;
  position: relative;
  margin: 20px 0;
  text-align: center;

  span {
    position: relative;
    background: #fff;
    padding: 0 20px;
    color: $cor_fonte_clara;
    font-size: 16px;
    text-align: center;
  }

  // Linha
  &::before {
    content: "";
    display: block;
    position: absolute;
    z-index: -1;
    top: 50%;
    left: 0;
    width: 100%;
    height: 2px;
    background: #ddd;
  }
}

.segura-infos-endereco {
  display: none;
}

.passo-a-passo-conta {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 20px;
}

// Item passo a passo
.passo-a-passo-item {
  position: relative;
  width: 175px;
  text-align: center;
  padding: 20px 10px;
  border-radius: 10px;
  background: $cor_fundo2;
  margin-bottom: 20px;

  // TABLET
  @include mq(m) {
    width: 220px;
  }

  // MOBILE
  @include mq(s) {
    width: calc(50% - 10px);
    margin-left: 5px;
    margin-right: 5px;
    margin-bottom: 10px;
    padding: 10px;
  }

  // Seta
  &::after {
    content: "";
    display: block;
    position: absolute;
    top: 45%;
    right: -15px;
    width: 15px;
    height: 15px;
    border-right: 1px solid;
    border-bottom: 1px solid;
    border-color: $cor_primaria;
    transform: rotate(-45deg);

    // TABLET
    @include mq(m) {
      right: -22px;
    }

    // MOBILE
    @include mq(s) {
      display: none;
    }
  }
  &:last-child::after {
    display: none;
  }
  &:nth-child(3n)::after {
    // TABLET
    @include mq(m) {
      display: none;
    }
  }

  // Destaque
  &.destaque {
    background: $cor_primaria;
  }

  // Ícone
  i {
    color: $cor_primaria;
    font-size: 28px;

    // MOBILE
    @include mq(s) {
      font-size: 24px;
    }
  }
  &.destaque i {
    color: $cor_primaria;
  }

  // Título
  h3 {
    color: $cor_fonte_escura;
    font-weight: 600;
    margin-top: 10px;
    margin-bottom: 5px;

    // MOBILE
    @include mq(s) {
      font-size: 14px;
    }
  }
  &.destaque h3 {
    color: #fff;
  }

  // Texto
  p {
    font-size: 14px;
    color: $cor_fonte_escura;

    // MOBILE
    @include mq(s) {
      font-size: 12px;
    }
  }
  &.destaque p {
    color: #fff;
  }
}

// Formas de pagamento
.formas-pagamento {
  float: left;
  width: 100%;
  // border: 1px solid red;
}

// Opção pagamento
.forma-pag-item {
  float: left;
  width: 100%;
  margin-bottom: 10px;
  padding: 10px;
  padding-top: 20px;
  border: 1px solid #ddd;

  // MOBILE
  @include mq(s) {
    padding-top: 10px;
  }

  // Radio button principal
  .campo-container.cr-container {
    margin: 0px;
    padding: 0px;

    .cr-lbl {
      margin: 0px;
      padding: 0px;
    }

    // Ícone
    i.fa {
      font-size: 20px;
      margin: 0 10px;
    }
  }
}

// Conteúdo opção pagamento
.forma-pag-content {
  display: none;
  padding-top: 10px;

  // Label nascimento
  label[for="dia_nasc"] {
    width: 350px;
  }
}

// Campos de foto
.campo-container-images {
  margin-bottom: 0px;

  // Dicas
  > label[for] > p {
    font-weight: normal;
    font-size: 15px;
    line-height: 100%;
    margin-bottom: 10px;

    b {
      font-weight: 600;
      color: $cor_primaria;
    }
  }
}

// Mensagem de erro
.error-img {
  float: left;
  width: 100%;
  text-align: center;
  color: $error_color;
  margin-top: 0px;
  font-size: 14px;
  line-height: 100%;
  font-weight: normal;

  b {
    font-weight: bold;
  }
}

// Mensagem de erro DROPZONE
.dz-msg-error {
  float: left;
  width: 100%;
  text-align: center;
  color: $error_color;
  margin-top: 10px;
  font-size: 16px;
  line-height: 100%;
  font-weight: normal;

  b {
    font-weight: bold;
  }
}

// Campo foto
.form-foto {
  width: 100%;
  max-width: 300px;
  margin: 0 auto;
  text-align: center;
  margin-bottom: 40px;

  label[for] {
    text-align: center;
  }

  // Ezdz
  .ezdz-dropzone {
    float: none;
    display: inline-block;
    max-width: 200px;
    height: 200px;
    line-height: 200px;
    border-radius: 50%;
    overflow: hidden;
  }
}

// Campo mapa
.campo-mapa {
  height: 250px;
  border: 2px solid #ddd;
}
