// Conteúdo página
.conta-content {
  padding: 20px;
  border: 1px solid #ddd;

  // MOBILE
  @include mq(s) {
    padding: 10px;
  }
}

// Botões superiores
.btns-top-user {
  float: left;
  width: 100%;
  margin-bottom: 20px;

  .btn-container {
    width: auto;
    margin-top: 0px;

    // MOBILE
    @include mq(s) {
      width: 100%;
      margin-bottom: 10px;
      text-align: center;
    }

    &.left {
      float: left;
    }

    &.right {
      float: right;
    }

    &.center {
      float: none;
      margin: 0 auto;
    }
  }

  .btn {
    position: relative;
    z-index: 2;
  }
}

// Tabela
.table-user {
  float: left;
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
  font-size: 15px;
  line-height: 18px;

  br {
    content: "";
    margin: 2em;
    display: block;
    font-size: 24%;
  }

  thead {
    border-bottom: 2px solid #ddd;

    // MOBILE
    @include mq(s) {
      display: none;
    }
  }

  tr {
    border-bottom: 1px solid #ddd;

    // MOBILE
    @include mq(s) {
      display: block;
      box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
      background: #fff;
      border: none;
      margin-bottom: 20px;
      padding: 10px 15px;
    }
  }

  th,
  td {
    text-align: left;
    padding: 20px 10px;
    vertical-align: middle;

    &.center {
      // TABLET
      @include mq(m) {
        text-align: center;
      }

      // DESKTOP
      @include mq(l) {
        text-align: center;
      }
    }

    &.opcoes {
      .btn-xm {
        margin: 3px;
      }
    }
  }

  th {
    color: $cor_fonte_escura;
    font-weight: bold;
  }

  td {
    position: relative;

    // MOBILE
    @include mq(s) {
      display: block;
      width: 100% !important;
      padding: 5px 0;
    }

    // Título de cada coluna (Mobile)
    &::before {
      content: attr(data-label);
      display: none;
      color: $cor_fonte_escura;
      margin-bottom: 7px;

      // MOBILE
      @include mq(s) {
        display: block;
      }
    }
  }

  small {
    font-size: 13px;
  }

  b {
    font-weight: bold;
    color: $cor_fonte_escura;
  }

  // Opções
  .forum-table-options {
    .btn {
      margin: 5px;

      i {
        margin-right: 5px;
      }
    }
  }
}

// Status
span.status {
  display: inline-block;
  background: $cor_primaria;
  font-size: 12px;
  line-height: 14px;
  text-transform: uppercase;
  color: #fff;
  padding: 5px 15px;
  border-radius: 10px;
  text-align: center;

  &.aprovado,
  &.publicado {
    background: $success_color;
  }

  &.pendente {
    background: $warning_color;
  }

  &.cancelado,
  &.inativo {
    background: $error_color;
  }
}

// Loading pagamento
.pagamento-loading {
  float: left;
  width: 100%;
  margin-bottom: 20px;
  padding: 20px 10px;
  background: $cor_fundo2;

  // MOBILE
  @include mq(s) {
    text-align: center;
  }

  // Ícone
  span {
    i {
      color: $cor_primaria;
      font-size: 50px;
    }
  }

  // Título
  h3 {
    font-size: 18px;
    font-weight: bold;
    color: $cor_fonte_escura;

    // MOBILE
    @include mq(s) {
      margin-top: 10px;
    }
  }
}
.loading-hidden {
  opacity: 0.3;
}

.confirmcao-bloco {
  background-color: $cor_fundo2;
  display: inline-block;
  margin: 0 auto;
  padding: 30px;
  text-align: left;
}

.btns-top-user {
  float: left;
  width: 100%;
}

.segura-data-documento,
.segura-categoria-documento {
  // MOBILE
  @include mq(s) {
    float: left;
    margin-bottom: 5px;
    width: 100%;
  }
}
