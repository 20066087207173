// Box-sizing
*,
*::after,
*::before {
  box-sizing: border-box;
}

// Remove outline de foco do firefox e outros browsers
:focus {
  outline: none;
}

::-moz-focus-inner {
  border: 0;
}

// Cor do texto selecionado
::selection {
  background: rgba($cor_primaria, .5);
}

::-moz-selection {
  background: rgba($cor_primaria, .5);
}

// Remove o fundo azul de itens clicados no chrome para android
* {
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
}

// Body
body {
  background: #FFF;
  font-family: $fonte_geral;
  font-size: 16px;
  line-height: 1.4;
  color: $cor_fonte_clara;
  padding-top: 122px;

  // TABLET
  @include mq(m) {
    padding-top: 99px;
  }

  // MOBILE
  @include mq(s) {
    padding-top: 91px;
  }
}

// Fix imagens
img {
  display: block;
  max-width: 100%;
}

// Seções
.secao {
  position: relative;
  float: left;
  width: 100%;
  padding-top: 40px;
  padding-bottom: 40px;

  // MOBILE
  @include mq(s) {
    padding-top: 20px;
    padding-bottom: 20px;
  }

  // Corrige o bug do scroll horizontal nas animações
  .container {
    overflow: hidden;
  }
}

.section-divider {
  position: relative;
  z-index: 2;
  float: left;
  width: 100%;
  height: 50px;
  background: none;

  svg {
    position: absolute;
    bottom: -1px;
    left: 0;
    width: 100%;
    height: 100%;

    path {
      fill: #FFF;
    }
  }

  &.small-bottom {
    //transform: rotate(180deg);

    svg {
      bottom: 49px;
    }

    path {
      fill: #FFF;
    }
  }

  &.small-bottom-footer svg path {
    fill: #036d78;
  }

  &.small-bottom-up {

    svg path {
      fill: darken(#5D628C, 8%);
    }
  }

  &.small-bottom-down {
    transform: rotate(180deg);

    svg path {
      fill: darken(#5D628C, 8%);
    }
  }
}
