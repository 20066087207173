// Header principal
.header-full {
  position: fixed;
  z-index: $z_index_header;
  top: 0;
  left: 0;
  float: left;
  width: 100%;
  background: #fff;
  //padding: 20px 0;
  padding-bottom: 14px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.26);
  transition: 0.5s ease;

  // MOBILE
  @include mq(s) {
    padding-top: 45px;
    padding-bottom: 10px;
    text-align: center;
  }
}

// Conteúdo (Container)
.header-full .container {
  position: static;
}

.header-full .header-content {
  position: relative;
  padding: 0 10px;

  // MOBILE
  @include mq(s) {
    position: static;
  }
}

// Logo
.header-full .header-logo {
  display: block;
  float: left;
  max-width: 270px;

  // TABLET
  @include mq(m) {
    max-width: 150px;
  }

  // MOBILE
  @include mq(s) {
    float: none;
    display: inline-block;
    vertical-align: top;
    max-width: 180px;
  }

  // Logo branco
  &.white-logo {
    display: none;
  }
}

// Direita
.header-full .header-direita {
  float: right;
  margin-top:0px;

  // MOBILE
  @include mq(s) {
    height: 0px;
  }
}

// Botões
.header-full .header-botoes {
  float: right;
  text-align: right;
  margin-left: 20px;
  margin-top: 12px;

  // TALBET
  @include mq(m) {
    margin-top: 5px;
  }

  // MOBILE
  @include mq(s) {
    width: 100%;
    text-align: center;
    margin-bottom: 10px;
    display: none;
  }

  .btn {
    margin-left: 10px;
    font-size: 14px;
    font-weight: 400;
    //line-height: 100%;
    padding: 10px 15px;

    // TABLET
    @include mq(m) {
      font-size: 9px;
    }

    // MOBILE
    @include mq(s) {
      font-size: 12px;
      margin: 5px;
      padding: 10px 15px;
    }
  }
}

// Telefones
.header-full .header-telefones {
  position: absolute;
  top: -20px;
  right: 10px;
  background-color: $cor_primaria;
  padding: 5px 10px;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  display: none;

  // MOBILE
  @include mq(s) {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    padding: 3px;
    background: $cor_fundo2;
    border-radius: 0px;
    width: 100%;
    text-align: center;
    white-space: nowrap;
    display: block;
  }

  // Item
  a {
    display: inline-block;
    font-weight: bold;
    font-size: 16px;
    text-transform: uppercase;
    color: #fff;
    margin: 0 5px;

    // TABLET
    @include mq(m) {
      font-size: 14px;
    }

    // MOBILE
    @include mq(s) {
      font-size: 14px;
      color: $cor_fonte_escura;
    }

    i {
      margin-right: 3px;

      // MOBILE
      @include mq(s) {
        color: $cor_primaria;
      }
    }
  }
}

// Barra
.header-barra {
  height: 35px;
  margin-bottom: 20px;
  background: $cor_fundo2;
  overflow: hidden;

  // TABLET
  @include mq(m) {
    height: 30px;
    display: block;
  }

  // MOBILE
  @include mq(s) {
    display: none;
  }
}

// Frase
.header-barra-frase {
  float: left;
  padding-left: 10px;
  padding-top: 7px;
  color: $cor_fonte_escura;
  font-size: 15px;

  // TABLET
  @include mq(m) {
    padding-top: 9px;
    font-size: 10px;
  }

  b {
    color: $cor_primaria;
    font-weight: 600;
  }
}

// Telefones
.header-barra-telefones {
  position: relative;
  float: right;
  padding: 0 10px;
  //padding-top: 7px;
  color: #fff;
  font-size: 15px;
  height: 35px;
  background: $cor_primaria;

  // TABLET
  @include mq(m) {
    font-size: 12px;
  }

  .segura-telefones {
    padding-top: 7px;

    &.frase-ipad {

      // TABLET
      @include mq(m) {
        display: none;
      }
    }
  }

  .btn {
    border-radius: 0px;
  }

  .btn-sm {
    padding: 12px 20px;
    font-size: 13px;
  }

  // Triângulo
  &::before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: -70px;
    width: 0px;
    height: 0px;
    border-right: 35px solid $cor_primaria;
    border-bottom: 35px solid transparent;
    border-left: 35px solid transparent;
  }

  // Barra
  &::after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    right: -1000px;
    width: 1000px;
    height: 35px;
    background: $cor_primaria;
  }

  // Unidade
  >div {
    float: right;
    margin-left: 20px;

    &:last-child {
      margin-left: 0px;
    }
  }

  // Nome unidade
  span {
    display: inline-block;
    font-weight: 600;
    margin-right: 5px;

    i {
      color: #fff;
      margin-right: 5px;
    }
  }

  // Telfone
  a {
    display: inline-block;
    color: #fff;
    font-weight: normal;
    transition: 0.2s ease-out;

    &:hover {
      color: #fff;
    }
  }
}


// ---------------------------- HOME ---------------------------- //
body.home-full {
  padding-top: 0px;
}

// Header
.home-full .header-full:not(.active) {
  background: none;
  background-image: linear-gradient(to bottom, rgba(#000, .95), rgba(#000, 0));
  box-shadow: none;
  padding-bottom: 50px;

  // MOBILE
  @include mq(s) {
    padding-top: 45px;
  }
}

// Logo
.home-full .header-full:not(.active) .header-logo {

  // Logo COLORIDO
  &.colored-logo {
    display: none;
  }

  // Logo BRANCO
  &.white-logo {
    display: block;

    // MOBILE
    @include mq(s) {
      display: inline-block;
    }
  }
}

// Telefones
.home-full .header-full:not(.active) .header-telefones {

  // MOBILE
  @include mq(s) {
    padding-top: 5px;
    padding-bottom: 5px;
    background: rgba(#fff, 0.1);
  }

  a {

    // MOBILE
    @include mq(s) {
      color: #fff;
    }
  }
}

// Botão
.home-full .header-full:not(.active) .outline {
  border: 1px solid #fff;
  color: #fff;

  &:hover,
  &:focus,
  &:active,
  &.active {
    background: #fff;
    color: $cor_primaria;
  }
}

// Menu lateral
.home-full .header-full:not(.active) .lateral .fdx-menu-1-level>li>a {
  color: #fff;

  &:hover {
    color: #fff;
  }

  &:before {
    background: #fff;
  }
}

// Botão menu mobile
.home-full .header-full:not(.active) .menu-btn-mobile {
  border-color: #fff;

  &::before,
  &::after {
    background: #fff;
  }
}

// Slide
.home-full .slide-item .slide-image {
  height: 100vh;
  min-height: 600px;

  // TABLET
  @include mq(m) {
    height: 90vh;
  }

  // MOBILE
  @include mq(s) {
    height: 80vh;
    min-height: 400px;
  }
}

.home-full .slide-item .slide-content {
  padding-top: 100px;
}

.home-full .header-full:not(.active) .header-barra {
  background: rgba(255, 255, 255, 0.1);
}

.home-full .header-full:not(.active) .header-barra-telefones {
  background: rgba(255, 255, 255, 0.1);

  &::before {
    border-right: 35px solid rgba(255, 255, 255, 0.1);
  }

  &::after {
    background: rgba(255, 255, 255, 0.1);
  }

  i {
    color: #FFF;
  }
}

.home-full .header-full:not(.active) .header-barra-frase {
  color: #fff;

  b {
    color: #fff;
  }
}
