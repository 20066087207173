// Cores do site
$cor_primaria: #036d78;
$cor_secundaria: #222222;

// Fundo 2 (Cor alternativa para o fundo do site)
$cor_fundo2: #F1F3F4;

// Status
$success_color: #3DAF57;
$warning_color: #FFBB33;
$error_color: #FA353D;

// Redes socias
$cor_facebook: #3B5998;
$cor_twitter: #1DA1F2;
$cor_youtube: #FE0000;
$cor_instagram: #C12595;
$cor_twitter: #1DA1F2;
$cor_tripadvisor: rgb(171, 179, 165);

// Fonte títulos
$fonte_titulo: 'Open Sans',
'Helvetica',
'Arial',
sans-serif;
// Fonte texto (Padrão)
$fonte_geral: 'Open Sans',
'Helvetica',
'Arial',
sans-serif;

// Cores dos textos
$cor_fonte_escura: #333;
$cor_fonte_clara: #777;

// Z-index (Z-INDEX de cada elemento do layout)
$z_index_geral: 2;
$z_index_menu: 4;
$z_index_header: 5;
$z_index_gotop: 5;
$z_index_menu_mobile: 6;
$z_index_modal: 7;
$z_index_mascara: 8;
