// Slide
.slide-home {
  float: left;
  width: 100%;

  // Modifica as cetas do SLICK para o slide
  .slick-prev,
  .slick-next {
    width: 80px;
    opacity: 0;

    // MOBILE
    @include mq(s) {
      width: 50px;
    }

    &::before {
      height: 30px;
      width: 30px;

      // MOBILE
      @include mq(s) {
        height: 20px;
        width: 20px;
      }
    }
  }

  .slick-dots {
    bottom: 10px;
  }
}

// Slide ITEM (Background Image)
.slide-item {
  position: relative;
  display: block;
  float: left;
  width: 100%;
  height: auto;
  overflow: hidden;
  background: #FFF;
  text-align: center;

  &[href="#"] {
    cursor: default;
  }

  // Slide IMAGEM
  .slide-image {
    width: 100%;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    height: 450px;

    // TABLET
    @include mq(m) {
      height: 350px;
    }

    // MOBILE
    @include mq(s) {
      height: 300px;
    }
  }
}

// Slide ITEM (Imagem padrão) OBS: Está desabilitado por padrão
.slide-item-auto {
  position: relative;
  display: block;
  float: left;
  width: 100%;
  height: auto;
  overflow: hidden;
  background: #FFF;
  text-align: center;

  &[href="#"] {
    cursor: default;
  }

  // Slide IMAGEM
  .slide-image {
    display: inline-block;
    vertical-align: bottom;
  }
}

// Slide MÁSCARA
.slide-mask {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: linear-gradient(to right, rgba(#000, .8), transparent);
  display: flex;
  align-items: center;
  justify-content: center;
}

// Slide CONTEÚDO
.slide-content {
  width: 100%;
  max-width: $largura_desktop;
  padding: 0 10px;
  text-align: center;
  display: none;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;

  // TABLET
  @include mq(m) {
    max-width: $largura_tablet;
  }

  // MOBILE
  @include mq(s) {
    max-width: $largura_smartphone;
    padding: 0 20px;
  }
}

// Slide TÍTULO
.slide-titulo {
  color: #FFF;
  font-family: $fonte_titulo;
  font-size: 28px;
  font-weight: bold;
  line-height: 1.2;
  text-transform: uppercase;
  margin-bottom: 30px;
  text-align: left;

  // Linha inferior
  &::after {
    content: '';
    display: block;
    position: absolute;
    margin: 10px auto;
    width: 100px;
    height: 2px;
    background: $cor_primaria;
  }

  // TABLET
  @include mq(m) {
    font-size: 24px;
  }

  // MOBILE
  @include mq(s) {
    font-size: 20px;
  }
}

// Slide TEXTO
.slide-texto {
  color: #FFF;
  font-family: $fonte_geral;
  font-size: 20px;
  font-weight: 300;
  line-height: 1.4;
  text-align: left;

  // TABLET
  @include mq(m) {
    font-size: 18px;
  }

  // MOBILE
  @include mq(s) {
    font-size: 14px;
  }
}

// Slide BOTÃO
.slide-btn {
  text-align: left;
  margin-top: 20px;

  .btn {

    // MOBILE
    @include mq(s) {
      font-size: 12px;
      padding: 12px 25px;
    }
  }
}

@-webkit-keyframes slide-fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

@keyframes slide-fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

.slide-fadeInUp {
  -webkit-animation-name: slide-fadeInUp;
  animation-name: slide-fadeInUp;
}
