.home-servicos {
  padding-top: 0px;
}

// Bloco serviço
.servico {
  position: relative;
  overflow: hidden;
  max-width: 500px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  margin-bottom: 20px;

  // Foto
  figure img {
    transform: scale(1.2);
    transition: .5s ease-out;
  }

  &:hover figure img {
    transform: scale(1);
  }

  // Máscara
  span {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(#000, .3);
    transition: .3s ease-out;

    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
  }

  &:hover span {
    background: rgba(#000, .6);
  }

  // Título
  h2 {
    font-size: 20px;
    line-height: 1.2;
    font-weight: bold;
    color: #FFF;
    text-transform: uppercase;
    text-shadow: 2px 2px 2px rgba(#000, .3);
    margin-top: 40px;
    margin-bottom: 15px;

    // MOBILE
    @include mq(s) {
      margin-top: 0px;
      font-size: 18px;
    }
  }

  // Botão
  .btn {
    opacity: 0;
    transform: scale(.5);

    // MOBILE
    @include mq(s) {
      opacity: 1;
      transform: scale(1);
    }
  }

  &:hover .btn {
    opacity: 1;
    transform: scale(1);
  }
}

// Carrosel
.carrosel-servicos {
  //margin-top: px;

  // Bloco serviço
  .bloco-servico {
    display: block;
    width: 100%;
    margin: 5px 10px;
  }
}

.bloco-servico {
  position: relative;
  background: #F1F3F4;
  width: 400px;
  //height: 75vh;
  border-radius: 6px;
  padding: 2rem;
  color: #aaa;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  overflow: hidden;
  margin-bottom: 20px;

  &.pag-servicos {
    width: 380px;

    // TABLET
    @include mq(m) {
      width: 360px;
    }

    // MOBILE
    @include mq(s) {
      width: 100%;
      margin-left: 0px;
      margin-right: 0px;
    }
  }

  &__img-container {
    margin: -2rem -2rem 1rem -2rem;

    figure {
      overflow: hidden;

      img {
        transition: .3s ease-out;
      }
    }
  }

  &:hover figure img {
    transform: scale(1.1);
  }

  &__linha {
    d: path("M -2 100 Q 50 200 100 250 Q 250 400 350 300 C 400 250 550 150 650 300 Q 750 450 802 400");
    stroke: $cor_primaria;
  }

  &__segura-image {
    transform: translateY(0) scale(1.0);
    filter: blur(0);
  }

  &__titulo {
    color: $cor_fonte_escura;
    margin-top: 0;
    font-weight: 800;
    letter-spacing: 0.01em;
    margin-bottom: 10px;
  }

  &__conteudo {
    margin-top: 25px;
    transform: translateY(0);
  }

  &__svg {
    position: absolute;
    left: 0;
    top: 102px;

    // TABLET
    @include mq(m) {
      top: 90px;
    }

    // MOBILE
    @include mq(s) {
      top: 72px;
    }
  }
}

/* @keyframes LineFadeIn {
  0% {
    opacity: 0;
    d: path("M 0 300 Q 0 300 0 300 Q 0 300 0 300 C 0 300 0 300 0 300 Q 0 300 0 300 ");
    stroke: #fff;
  }

  50% {
    opacity: 1;
    d: path("M 0 300 Q 50 300 100 300 Q 250 300 350 300 C 350 300 500 300 650 300 Q 750 300 800 300");
    stroke: #888BFF;
  }

  100% {
    opacity: 1;
    d: path("M -2 100 Q 50 200 100 250 Q 250 400 350 300 C 400 250 550 150 650 300 Q 750 450 802 400");
    stroke: $cor_primaria;
  }
}
 */
/* @keyframes ContentFadeIn {
  0% {
    transform: translateY(-1rem);
    opacity: 0;
  }

  100% {
    transform: translateY(0);
    opacity: 1;
  }
} */

/* @keyframes ImageFadeIn {
  0% {
    transform: translate(-.5rem, -.5rem) scale(1.05);
    opacity: 0;
    filter: blur(2px);
  }

  50% {
    opacity: 1;
    filter: blur(2px);
  }

  100% {
    transform: translateY(0) scale(1.0);
    opacity: 1;
    filter: blur(0);
  }
}
 */
