// Banner do topo
.institucional-banner {
  position: relative;
  float: left;
  width: 100%;
  height: 200px;
  background-color: #DDD;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;

  &.banner-titulo {
    height: auto;
    padding: 55px 0;
    text-shadow: 2px 2px 2px rgba(#000, .3);

    // MOBILE
    @include mq(s) {
      padding: 30px 0;
    }
  }

  .pag-contato {
    margin-bottom: 75px;

    // MOBILE
    @include mq(s) {
      margin-bottom: 30px;
    }
  }

  h1 {
    font-size: 28px;
    line-height: 1.2;
    font-weight: bold;
    color: #FFF;
    text-align: center;
    position: relative;
    font-family: $fonte_titulo;
    text-transform: uppercase;

    // Linha inferior
    &::after {
      content: "";
      display: block;
      position: absolute;
      left: 0;
      right: 0;
      bottom: -15px;
      margin: 0 auto;
      width: 100px;
      height: 2px;
      background: $cor_secundaria;

      // MOBILE
      @include mq(s) {
        bottom: -10px;
      }
    }

    // MOBILE
    @include mq(s) {
      font-size: 20px;
    }
  }

  span {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: rgba($cor_primaria, .9);
  }

  h2 {
    color: #FFF;
    margin-top: 90px;

    // MOBILE
    @include mq(s) {
      margin-top: 64px;
      font-size: 13px;
    }

    strong {
      color: #FFF;
      font-weight: bold;
    }
  }

  // TABLET
  @include mq(m) {
    height: 150px;
  }

  // MOBILE
  @include mq(s) {
    height: 100px;
  }
}

/* Segura o texto das páginas instituicionais para evitar que fiquem com 100% de largura e prejudique a leitura */
.segura-texto {

  // DESKTOP
  @include mq(l) {
    float: none;
    margin-left: auto;
    margin-right: auto;
  }
}
